@import "../css/fontweights.css";
@import "../css/colors.css";
@import "../css/margins.css";
@import "../css/paddings.css";
@import "../css/fontsizes.css";
:root {
    line-height: 1.6;
    --bg-color-g: grey;
    --color-wl: rgba(236, 230, 230, 0.91);
    --color-primary: rgba(31, 54, 168, 0.91);
    --linear-purple-orange: linear-gradient(to right, rgba(80, 17 ,136, 0.9), rgba(223 ,73, 73, 0.99));
    --linear-purple-orange-lighter: linear-gradient(to right, rgba(80, 17, 136, 0.9), rgb(245, 58, 58));
    --linear-purple-grey: linear-gradient(to right, rgba(46, 24, 70, 0.9), rgb(92, 85, 119));
    --linear-purple-light: linear-gradient(to right, rgba(46, 24, 70, 0.9), rgb(148, 143, 164));
    font-family: Slack-Circular-Pro, "Helvetica Neue", Helvetica, "Segoe UI",
    Tahoma, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-size: 62.5%;
}

.home-image-easy {
    background: url("/assets/img.png") no-repeat;
    object-fit: cover;
}
*,
*::after, *::before {
    box-sizing: border-box;
}
::selection {
    background-color: var(--color-primary);
    color: white;
}

.container {
    max-width: 82%;
    margin: auto;
}

body {
    background: var(--linear-purple-orange);
    color: var(--color-wl);
    font-size: 2.4rem !important;
}

h1,
h2,
h3 {
    color: var(--color-dark-purple-w);
    /*margin-bottom: 1rem !important;*/
    line-height: 1.1;
}

p {
    margin-top: 0;
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 4rem;
}

h3 {
    font-size: 3rem;
    font-weight: 500;
}


@media screen and (min-width: 1024px) {
    body {
        font-size: 1.8rem;
    }
    /*h1 {*/
    /*    font-size: 8rem;*/
    /*}*/
    h2 {
        font-size: 4rem;
    }
    h3 {
        font-size: 2.4rem;
    }
}

.nav {
    justify-self: center;
    transition: all 0.3s;
}
.nav-logo {
    transition: all 0.3s;
}
.nav-search {
    width: max-content;
    transition: all 0.3s;
}
.nav-search input::placeholder {
    color: white;
}

.nav-search input {
    border-radius: unset;
    background: transparent;
    color: white;
    padding: 1.5rem;
    transition: all 0.3s;
    font-size: 60%;
}

.nav-menu {
  display: flex;
  align-content: space-between;
  list-style-type: none;
  /*padding-inline-start: 1rem;*/
}

.nav-menu__item {
    transition: all 0.3s;
    align-self: center;
    margin: 0 0.8rem;
    cursor: pointer;
}
.nav-menu__item .icon-container {
    width: unset;
    height: unset;
    padding: 1rem;
    transition: all 0.3s;
    color: #342862;
}

.icon-container:hover {
   background: var(--linear-purple-orange);
   color: white;
   box-shadow: 0 0 1px rgb(0 0 0 / 10%), 0 0 10px rgb(1 1 12 / 10%);
   transform: scale(0.9);
}

.icon-container .material-icons {
    font-size: 2.9rem;
    transition: all 0.3s;
}
.icon-container--small {
    transition: all 0.3s;
    color: #342862;
    width: 49px;
    height: 49px;
}
.icon-container .material-icons:hover {
    transform: scale(1.1);
}


.icon-container .nav-menu__item__text {
    letter-spacing: normal;
    text-align: center;
    transition: all 0.3s;
}

.nav-menu__item .nav-menu__item__text:hover {
    letter-spacing: 0.4rem;
    text-align-last: center;
    transform: scale(0.9);
    font-size: 3rem;
}

/* INPUTS */
.input-group {
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 4.5rem;
    align-self: center;
    border: 1px solid rgba(156, 148, 173, 0.95);
}

.input-group .icon {
    margin-right: 1rem;
    margin-top: 0.4rem;
    transform: scale(0.6);
}

/* ICONS*/
.icon {
    width: 40px;
    height: 40px;
}

.icon--small {
    transform: scale(0.8);
}
.icon--large {
    transform: scale(1.2);
}
.icon--primary {
    fill: var(--color-primary);
}
.icon--white {
    fill: white;
}
.icon--secondary {
    fill: var(--color-secondary);
}
.icon--accent {
    fill: var(--color-accent);
}

.icon-container {
    background: #f3f9fa;
    width: available;
    height: available;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.icon-container--accent {
    background-color: var(--color-accent);
}

@media screen and (max-width: 1338px){
    .nav-search input {
        width: 0;
        display: none;
    }
    .input-group .icon {
        display: none;;
    }
    .input-group {
        border: none;
    }
    .nav-logo {
        transform: scale(0.8);
    }
    .nav-search {
        content: url("/assets/icons/search.svg");
        width: 40px;
        fill: #f3f9fa;
        height: 40px;
        margin: 1rem 0 0 0;
        transform: scale(0.6);
    }
}
@media screen and (max-width: 768px){
    .nav-logo {
        transform: scale(0.6);
    }
    .nav-search {
        transform: scale(0.6);
    }
}

.title {
  color: var(--color-wl) ;
}

pre.prettyprint {
    margin: unset;
    border: none !important;
    background: transparent;
}
.prettyprint {
    background: none !important;
}
pre.prettyprint span {
    padding: unset !important;
}
.table-generator {
    margin: 0 2rem;
}
.table-generator__title {
    margin: 0.5rem 1rem;
}
.table-generator__table {
    /*margin: 0 2rem;*/
}

.table-generator__payloads {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));
    justify-items: center;
    margin: 4rem auto;
    gap: 1rem;
}

.table-generator__payloads-item {
    border-radius: 7px;
    max-width: 596px;
    box-shadow: 1px 1px 20px rgb(0 0 0 / 30%), 1px 2px 20px rgb(1 1 12 / 30%);
    color: white;
    min-width: available;
}

li.L0, li.L1, li.L2, li.L3,
li.L5, li.L6, li.L7, li.L8, li.L9 {
    list-style-type: decimal !important;
    background: none !important;
    color: white !important;
}
/* TEXT HTML */
xmp {
    margin: 0;
}

/* INPUTS */
.input {
    min-width: 100%;
    padding: 1.5rem;
    border: none;
    min-height: 49px;
    border-radius: 7px;
    outline: 0;
    background-color: aliceblue;
    transition: min-width 0.3s;
}
.custom-table-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.custom-table-form__icons {
    margin: 1rem 0;
}

/* HERO */
.hero {
    margin: 6rem 4rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #8661853b;
}

.header {
    margin: 2rem 0;
    text-align: right;
}

.hero-buttons {
    display: flex;
    margin: 6rem 0 0 0;
    transition: all 0.3s;
}
.hero-buttons .button:nth-child(1) {
    background-color: var(--light-purple);
}
.hero-buttons .button:nth-child(2) {
    background-color: var(--dark-purple);
}

.hero-buttons .button {
    margin: 0 1rem 0 0;
    transition: all 0.3s;
}

.hero__header {
    font-size: 4rem;
    font-weight: 600;
    color: white;
    margin: 0 0 3rem 0;
}
.hero p {
    font-weight: 600;
    color: #e4daede6;
    font-size: 1.4rem;
    text-align: justify;
}

.hero__grid {
    gap: 4rem;
    height: 100%;
}

/* BUTTONS */
.button, .button-outlined {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 10%), 1px 2px 5px rgb(1 1 12 / 10%);
}

.shadow {
    box-shadow: 1px 1px 5px rgb(0 0 0 / 10%), 1px 2px 5px rgb(1 1 12 / 10%);
}

.button {
    border: none;
    border-radius: 7px;
    padding: 1rem;
    background: var(--color-primary);
    color: white;
    cursor: pointer;
    transition: all 0.1s;
}

.button-outlined {
    border: 1px solid rgba(255, 255, 255, 0.59);
    border-radius: 7px;
    padding: 0.6rem;
    background: transparent;
    color: rgba(255, 255, 255, 0.89);
    transition: all 0.1s;
}

.icon-button {
    padding: 0.22rem 0.5rem;
    gap: 1.10rem;
}

.terminal {
    position: relative;
}
.termina__line {
    height: 1px;
    width: 100%;
    background: #2a2323;
    display: block;
}
.terminal-custom__card {
    background: #29282b !important;
}

.terminal .prettyprint  {
    /*font-size: 60%;*/
}

.terminal-button {
    margin: 3rem 0 0 0;
    position: absolute;
    right: 0;
}

.terminal-button__text {
    text-align-last: left;
}

.terminal__icons {
    display: flex;
    padding: 1rem;
}

.terminal__icons span {
    display: inline-block;
    padding-right: 0.21rem;
}
.terminal__icons .material-icons {
    font-size: 12px;
}
.terminal__icons span:nth-child(2) {
    color: #ffb807;
}
.terminal__icons span:nth-child(1) {
    color: #e4513e;
}
.terminal__icons span:nth-child(3) {
    color: #7cb188;
}

.prettyprint.prettyprinted, .table-generator__payloads-item {
    background: rgb(2, 17, 40);
}

/* DOCS */
.docs {
    background: var(--bg-color-g);
}

.block {
    --padding-vertical: 6rem;
    padding: var(--padding-vertical) 2rem;
}
.block--skewed-right {
    /*calculate with calc */
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%,100% 0%, 100% 100%, 0% 90%);
}
.block--skewed-left {
    /*calculate with calc */
    padding-bottom: calc(var(--padding-vertical) + 4rem);
    clip-path: polygon(0% 0%,100% 0%, 100% 90%, 0% 100%);
}

/* LINKS */
/* LINKS */
a {
    text-decoration: none;
}

.arrowed-link {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
}

.card {
    background: radial-gradient(ellipse at 10%, #303082, rgba(58, 0, 121, 0.78));
    border-radius: 12px;
    box-shadow: 1px 1px 5px rgba(1 1 10 / 30%), 1px 2px 5px rgba(1 1 10 / 30%);
}

.arrow__icon {
    vertical-align: sub;
    margin-left: 5px;
    transition: margin 0.15s;
}

.hover-animation {
   transition: all 0.3s;
}
.hover-animation-button {
    transition: all 0.2s;
}
.hover-animation-button--primary {
    transition: all 0.2s;
}

/* TODO use variables please */
.hover-animation:hover {
    box-shadow: 1px 1px 5px rgba(1 1 10 / 30%), 1px 2px 5px rgba(1 1 10 / 30%);
}
.hover-animation-button:hover {
    box-shadow: 1px 1px 5px rgba(1 1 10 / 30%), 1px 2px 5px rgba(1 1 10 / 30%);
}
.hover-animation-button--primary:hover {
    box-shadow: 1px 1px 5px rgba(1 1 10 / 30%), 1px 2px 5px rgba(1 1 10 / 30%);
}

.alternate-background:nth-child(EVEN) {
    background: radial-gradient(circle at 100%, rgba(48, 48, 130, 0.98), rgba(16, 0, 38, 0.33));
    border-radius: 12px;
    box-shadow: 1px 1px 5px rgba(1 1 10 / 30%), 1px 2px 5px rgba(1 1 10 / 30%);
}

.alternate-background:nth-child(EVEN):hover {
    background: radial-gradient(circle at 100%, rgba(48, 48, 130, 0.98), rgba(87, 0, 176, 0.5));
    border-radius: 12px;
    box-shadow: 1px 1px 5px rgba(1 1 10 / 30%), 1px 2px 5px rgba(1 1 10 / 30%);
}

.arrowed-link:hover > .arrow__icon {
    margin-right: 10px;
}

.nav-menu__icon {
    transition: all 0.3s;
    display: none;
}

@media screen and (min-width: 1024px) {
    .arrowed-link {
        font-size: 1.5rem;
    }
    .arrow__icon {
        transition: margin 0.15s;
    }
}

@media screen and (max-width: 978px) {
    .navbar {
        transition: all 0.3s;
        grid-template-columns: 1fr !important;
    }
    .navbar .nav-logo {
        transition: all 0.3s;
        transform: scale(0.8);
        padding: 1rem;
        margin: auto;
        text-align: center;
        align-self: center;
    }
}
@media screen and (min-width: 1024px) {
    .navbar .nav-logo {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 968px) {
    .nav-menu__icon {
        display: block;
    }
    .nav-menu__item:not(:nth-child(7)) {
        transition: all 0.3s;
        display: none;
    }
}
@media screen and (max-width: 530px) {
    .nav-logo {
        transition: all 0.3s;
        display: none;
    }
}

/* SHOWCASE */
.showcase {
    transition: all 0.3s;
}
.showcase__content {
    padding: 4rem;
    transition: all 0.3s;
}
.showcase__header {
    font-size: 2.5rem;
    transition: all 0.3s;
}

.showcase__text {
    font-size: 2.7rem;
    font-weight: 600;
    transition: all 0.3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-block-start: 0;
    margin-block-end: 0;
}

@media screen and (min-width: 1024px) {
    .showcase__header {
        font-size: 4rem;
    }
    .hero__text {
        font-size: 2.2rem;
    }

    .showcase__text {
        font-size: 2.7rem;
        font-weight: 600;
        transition: all 0.3s;
    }
}

/* card list */
.card-list {

}


.material-icons[id*="star"] {
    transition: all 0.5s;
}

body {
    transition: all 0.5s;
    animation: animate-body 0.5s;
}
body:hover {
    transition: all 0.3s;
    background: var(--linear-purple-orange-lighter);
}

.material-icons[id*="star"]:hover {
    color: #f84e31;
    transition: all 0.3s;
    animation: rotateStar 0.3s;
}

.material-icons[id*="star"]:nth-child(ODD):hover {
    color: #d31fd0;
    transition: all 0.3s;
}
.material-icons[id*="star"]:nth-child(EVEN):hover {
    color: #d31fd0;
    transition: all 0.3s;
}

@keyframes rotateStar {
    0% {
        transform: scale(1.2) rotate(7deg);
    }
    40% {
        color: rgba(248, 41, 6, 0.68) ;
        transform: rotate(-7deg);
    }
    to {
        transform: scale(0.9) ;
    }
}

.card-list__item:hover {
    transform: scale(0.95);
}

.card-list__item {
    cursor: pointer;
    transition: all 0.3s;
}

.card-list__item[class*="card"] {
    animation: sanimate-child 1s;
}

@keyframes animate-body {
    0% {
        transform: translateY(0%);
    }
    30% {
        transform: translateY(100%);
    }
    60% {
        transform: scale(.9);
    }
    to {
       transform: scale(1);
    }
}

@keyframes animate-child {
    0% {
        transform: rotate(9deg);
        border-radius: 0px;
    }
    to {
        transform: rotate(360deg);
    }
}

.add-icon__cv-form {
    /*position: absolute;*/
    width: 49px;
    height: 49px;
    /*margin: 1rem 0;*/
    display: inline-block;
    /*right: 1rem;*/
}

.add-form__left {
    padding-inline-start: 1rem !important;
}

.alternate-visibility {
  display: block;
  transition: all 0.5s
}
.alternate-visibility-none {
    display: none;
    transition: all 0.5s
}
.search-icon svg {
    fill: var(--dark-purple);
}
