.m1 {
  margin: 1rem;
}
.m2 {
  margin: 2rem;
}
.m3 {
  margin: 3rem;
}
.m4 {
  margin: 3rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}
.ml3 {
  margin-left: 3rem;
}
.ml4 {
  margin-left: 4rem;
}

.mb1 {
  margin-bottom: 1rem;
}
.mb2 {
  margin-bottom:2rem;
}
.mb3 {
  margin-bottom: 3rem;
}
.mb4 {
  margin-bottom: 4rem;
}

.mt1 {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}
.mt3 {
  margin-top: 3rem;
}
.mt4 {
  margin-top: 4rem;
}

.mr1 {
  margin-right: 1rem;
}
.mr2 {
  margin-right: 2rem;
}
.mr3 {
  margin-right: 3rem;
}
.mr4 {
  margin-right: 4rem;
}
