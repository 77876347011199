:root {
    --xxl: 6rem;
    --xl: 5rem;
    --l: 4rem;
    --m: 3rem;
    --s: 2rem;
    --xs: 1rem;
    --xxs: 0.5rem;
}

.xxl {
    font-size: var(--xxl);
}
.xl {
    font-size: var(--xl);
}

.l {
    font-size: var(--l);
}
.m {
    font-size: var(--m);
}

.s {
    font-size: var(--s);
}

.xs {
    font-size: var(--xs);
}

.xxs {
    font-size: 3rem;
}
