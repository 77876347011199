.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

:root {
  --color-primary: #2584ff;
  --color-secondary: #00aac7;
  --color-accent: #ff3400;
  --dark-purple: #342862;
  --color-dark-purple-w: #bfb9c7;
  --color-body: #918ca4;
  --light-purple: #5c5577;
  --color-pink-light: #dfbfe4;
}

.pink-light {
  color: var(--color-pink-light);
}

.blue-primary {
  color: var(--color-primary);
}

.blue-secondary {
  color: var(--color-secondary);
}

.dark-purple {
  color: var(--dark-purple);
}

.light-purple {
  color: var(--light-purple);
}

.m1 {
  margin: 1rem;
}

.m2 {
  margin: 2rem;
}

.m3, .m4 {
  margin: 3rem;
}

.ml1 {
  margin-left: 1rem;
}

.ml2 {
  margin-left: 2rem;
}

.ml3 {
  margin-left: 3rem;
}

.ml4 {
  margin-left: 4rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mb4 {
  margin-bottom: 4rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mr1 {
  margin-right: 1rem;
}

.mr2 {
  margin-right: 2rem;
}

.mr3 {
  margin-right: 3rem;
}

.mr4 {
  margin-right: 4rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3, .p4 {
  padding: 3rem;
}

.pl1 {
  padding-left: 1rem;
}

.pl2 {
  padding-left: 2rem;
}

.pl3 {
  padding-left: 3rem;
}

.pl4 {
  padding-left: 4rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pt4 {
  padding-top: 4rem;
}

.pr1 {
  padding-right: 1rem;
}

.pr2 {
  padding-right: 2rem;
}

.pr3 {
  padding-right: 3rem;
}

.pr4 {
  padding-right: 4rem;
}

:root {
  --xxl: 6rem;
  --xl: 5rem;
  --l: 4rem;
  --m: 3rem;
  --s: 2rem;
  --xs: 1rem;
  --xxs: .5rem;
}

.xxl {
  font-size: var(--xxl);
}

.xl {
  font-size: var(--xl);
}

.l {
  font-size: var(--l);
}

.m {
  font-size: var(--m);
}

.s {
  font-size: var(--s);
}

.xs {
  font-size: var(--xs);
}

.xxs {
  font-size: 3rem;
}

:root {
  --bg-color-g: grey;
  --color-wl: #ece6e6e8;
  --color-primary: #1f36a8e8;
  --linear-purple-orange: linear-gradient(to right, #501188e6, #df4949fc);
  --linear-purple-orange-lighter: linear-gradient(to right, #501188e6, #f53a3a);
  --linear-purple-grey: linear-gradient(to right, #2e1846e6, #5c5577);
  --linear-purple-light: linear-gradient(to right, #2e1846e6, #948fa4);
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: Slack-Circular-Pro, Helvetica Neue, Helvetica, Segoe UI, Tahoma, Arial, sans-serif;
  font-size: 62.5%;
  line-height: 1.6;
}

.home-image-easy {
  object-fit: cover;
  background: url("img.4e8a930d.png") no-repeat;
}

*, :after, :before {
  box-sizing: border-box;
}

::selection {
  background-color: var(--color-primary);
  color: #fff;
}

.container {
  max-width: 82%;
  margin: auto;
}

body {
  background: var(--linear-purple-orange);
  color: var(--color-wl);
  font-size: 2.4rem !important;
}

h1, h2, h3 {
  color: var(--color-dark-purple-w);
  line-height: 1.1;
}

p {
  margin-top: 0;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
  font-weight: 500;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

.nav {
  justify-self: center;
  transition: all .3s;
}

.nav-logo {
  transition: all .3s;
}

.nav-search {
  width: max-content;
  transition: all .3s;
}

.nav-search input::placeholder {
  color: #fff;
}

.nav-search input {
  border-radius: unset;
  color: #fff;
  background: none;
  padding: 1.5rem;
  font-size: 60%;
  transition: all .3s;
}

.nav-menu {
  align-content: space-between;
  list-style-type: none;
  display: flex;
}

.nav-menu__item {
  cursor: pointer;
  align-self: center;
  margin: 0 .8rem;
  transition: all .3s;
}

.nav-menu__item .icon-container {
  width: unset;
  height: unset;
  color: #342862;
  padding: 1rem;
  transition: all .3s;
}

.icon-container:hover {
  background: var(--linear-purple-orange);
  color: #fff;
  transform: scale(.9);
  box-shadow: 0 0 1px #0000001a, 0 0 10px #01010c1a;
}

.icon-container .material-icons {
  font-size: 2.9rem;
  transition: all .3s;
}

.icon-container--small {
  color: #342862;
  width: 49px;
  height: 49px;
  transition: all .3s;
}

.icon-container .material-icons:hover {
  transform: scale(1.1);
}

.icon-container .nav-menu__item__text {
  letter-spacing: normal;
  text-align: center;
  transition: all .3s;
}

.nav-menu__item .nav-menu__item__text:hover {
  letter-spacing: .4rem;
  text-align-last: center;
  font-size: 3rem;
  transform: scale(.9);
}

.input-group {
  height: 4.5rem;
  border: 1px solid #9c94adf2;
  border-radius: 50px;
  align-self: center;
  align-items: center;
  display: flex;
}

.input-group .icon {
  margin-top: .4rem;
  margin-right: 1rem;
  transform: scale(.6);
}

.icon {
  width: 40px;
  height: 40px;
}

.icon--small {
  transform: scale(.8);
}

.icon--large {
  transform: scale(1.2);
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: #fff;
}

.icon--secondary {
  fill: var(--color-secondary);
}

.icon--accent {
  fill: var(--color-accent);
}

.icon-container {
  width: available;
  height: available;
  background: #f3f9fa;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon-container--accent {
  background-color: var(--color-accent);
}

@media screen and (max-width: 1338px) {
  .nav-search input {
    width: 0;
    display: none;
  }

  .input-group .icon {
    display: none;
  }

  .input-group {
    border: none;
  }

  .nav-logo {
    transform: scale(.8);
  }

  .nav-search {
    content: url("search.dd6b3e1b.svg");
    width: 40px;
    fill: #f3f9fa;
    height: 40px;
    margin: 1rem 0 0;
    transform: scale(.6);
  }
}

@media screen and (max-width: 768px) {
  .nav-logo, .nav-search {
    transform: scale(.6);
  }
}

.title {
  color: var(--color-wl);
}

pre.prettyprint {
  margin: unset;
  background: none;
  border: none !important;
}

.prettyprint {
  background: none !important;
}

pre.prettyprint span {
  padding: unset !important;
}

.table-generator {
  margin: 0 2rem;
}

.table-generator__title {
  margin: .5rem 1rem;
}

.table-generator__payloads {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-items: center;
  gap: 1rem;
  margin: 4rem auto;
  display: grid;
}

.table-generator__payloads-item {
  max-width: 596px;
  color: #fff;
  min-width: available;
  border-radius: 7px;
  box-shadow: 1px 1px 20px #0000004d, 1px 2px 20px #01010c4d;
}

li.L0, li.L1, li.L2, li.L3, li.L5, li.L6, li.L7, li.L8, li.L9 {
  color: #fff !important;
  background: none !important;
  list-style-type: decimal !important;
}

xmp {
  margin: 0;
}

.input {
  min-width: 100%;
  min-height: 49px;
  background-color: #f0f8ff;
  border: none;
  border-radius: 7px;
  outline: 0;
  padding: 1.5rem;
  transition: min-width .3s;
}

.custom-table-form {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  display: grid;
}

.custom-table-form__icons {
  margin: 1rem 0;
}

.hero {
  background: #8661853b;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 6rem 4rem;
}

.header {
  text-align: right;
  margin: 2rem 0;
}

.hero-buttons {
  margin: 6rem 0 0;
  transition: all .3s;
  display: flex;
}

.hero-buttons .button:nth-child(1) {
  background-color: var(--light-purple);
}

.hero-buttons .button:nth-child(2) {
  background-color: var(--dark-purple);
}

.hero-buttons .button {
  margin: 0 1rem 0 0;
  transition: all .3s;
}

.hero__header {
  color: #fff;
  margin: 0 0 3rem;
  font-size: 4rem;
  font-weight: 600;
}

.hero p {
  color: #e4daede6;
  text-align: justify;
  font-size: 1.4rem;
  font-weight: 600;
}

.hero__grid {
  height: 100%;
  gap: 4rem;
}

.button, .button-outlined, .shadow {
  box-shadow: 1px 1px 5px #0000001a, 1px 2px 5px #01010c1a;
}

.button {
  background: var(--color-primary);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  padding: 1rem;
  transition: all .1s;
}

.button-outlined {
  color: #ffffffe3;
  background: none;
  border: 1px solid #ffffff96;
  border-radius: 7px;
  padding: .6rem;
  transition: all .1s;
}

.icon-button {
  gap: 1.1rem;
  padding: .22rem .5rem;
}

.terminal {
  position: relative;
}

.termina__line {
  height: 1px;
  width: 100%;
  background: #2a2323;
  display: block;
}

.terminal-custom__card {
  background: #29282b !important;
}

.terminal-button {
  margin: 3rem 0 0;
  position: absolute;
  right: 0;
}

.terminal-button__text {
  text-align-last: left;
}

.terminal__icons {
  padding: 1rem;
  display: flex;
}

.terminal__icons span {
  padding-right: .21rem;
  display: inline-block;
}

.terminal__icons .material-icons {
  font-size: 12px;
}

.terminal__icons span:nth-child(2) {
  color: #ffb807;
}

.terminal__icons span:nth-child(1) {
  color: #e4513e;
}

.terminal__icons span:nth-child(3) {
  color: #7cb188;
}

.prettyprint.prettyprinted, .table-generator__payloads-item {
  background: #021128;
}

.docs {
  background: var(--bg-color-g);
}

.block {
  --padding-vertical: 6rem;
  padding: var(--padding-vertical) 2rem;
}

.block--skewed-right {
  padding-bottom: calc(var(--padding-vertical)  + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 90%);
}

.block--skewed-left {
  padding-bottom: calc(var(--padding-vertical)  + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
}

a {
  text-decoration: none;
}

.arrowed-link {
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  font-size: 2rem;
  font-weight: bold;
}

.card {
  background: radial-gradient(at 10%, #303082, #3a0079c7);
  border-radius: 12px;
  box-shadow: 1px 1px 5px #01010a4d, 1px 2px 5px #01010a4d;
}

.arrow__icon {
  vertical-align: sub;
  margin-left: 5px;
  transition: margin .15s;
}

.hover-animation {
  transition: all .3s;
}

.hover-animation-button, .hover-animation-button--primary {
  transition: all .2s;
}

.hover-animation:hover, .hover-animation-button:hover, .hover-animation-button--primary:hover {
  box-shadow: 1px 1px 5px #01010a4d, 1px 2px 5px #01010a4d;
}

.alternate-background:nth-child(2n) {
  background: radial-gradient(circle at 100%, #303082fa, #10002654);
  border-radius: 12px;
  box-shadow: 1px 1px 5px #01010a4d, 1px 2px 5px #01010a4d;
}

.alternate-background:nth-child(2n):hover {
  background: radial-gradient(circle at 100%, #303082fa, #5700b080);
  border-radius: 12px;
  box-shadow: 1px 1px 5px #01010a4d, 1px 2px 5px #01010a4d;
}

.arrowed-link:hover > .arrow__icon {
  margin-right: 10px;
}

.nav-menu__icon {
  transition: all .3s;
  display: none;
}

@media screen and (min-width: 1024px) {
  .arrowed-link {
    font-size: 1.5rem;
  }

  .arrow__icon {
    transition: margin .15s;
  }
}

@media screen and (max-width: 978px) {
  .navbar {
    transition: all .3s;
    grid-template-columns: 1fr !important;
  }

  .navbar .nav-logo {
    text-align: center;
    align-self: center;
    margin: auto;
    padding: 1rem;
    transition: all .3s;
    transform: scale(.8);
  }
}

@media screen and (min-width: 1024px) {
  .navbar .nav-logo {
    transform: scale(.8);
  }
}

@media screen and (max-width: 968px) {
  .nav-menu__icon {
    display: block;
  }

  .nav-menu__item:not(:nth-child(7)) {
    transition: all .3s;
    display: none;
  }
}

@media screen and (max-width: 530px) {
  .nav-logo {
    transition: all .3s;
    display: none;
  }
}

.showcase {
  transition: all .3s;
}

.showcase__content {
  padding: 4rem;
  transition: all .3s;
}

.showcase__header {
  font-size: 2.5rem;
  transition: all .3s;
}

.showcase__text {
  font-size: 2.7rem;
  font-weight: 600;
  transition: all .3s;
}

h1, h2, h3, h4, h5, h6 {
  margin-block: 0;
}

@media screen and (min-width: 1024px) {
  .showcase__header {
    font-size: 4rem;
  }

  .hero__text {
    font-size: 2.2rem;
  }

  .showcase__text {
    font-size: 2.7rem;
    font-weight: 600;
    transition: all .3s;
  }
}

.material-icons[id*="star"] {
  transition: all .5s;
}

body {
  transition: all .5s;
  animation: animate-body .5s;
}

body:hover {
  background: var(--linear-purple-orange-lighter);
  transition: all .3s;
}

.material-icons[id*="star"]:hover {
  color: #f84e31;
  transition: all .3s;
  animation: rotateStar .3s;
}

.material-icons[id*="star"]:nth-child(2n+1):hover, .material-icons[id*="star"]:nth-child(2n):hover {
  color: #d31fd0;
  transition: all .3s;
}

@keyframes rotateStar {
  0% {
    transform: scale(1.2)rotate(7deg);
  }

  40% {
    color: #f82906ad;
    transform: rotate(-7deg);
  }

  to {
    transform: scale(.9);
  }
}

.card-list__item:hover {
  transform: scale(.95);
}

.card-list__item {
  cursor: pointer;
  transition: all .3s;
}

.card-list__item[class*="card"] {
  animation: sanimate-child 1s;
}

@keyframes animate-body {
  0% {
    transform: translateY(0%);
  }

  30% {
    transform: translateY(100%);
  }

  60% {
    transform: scale(.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes animate-child {
  0% {
    border-radius: 0;
    transform: rotate(9deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.add-icon__cv-form {
  width: 49px;
  height: 49px;
  display: inline-block;
}

.add-form__left {
  padding-inline-start: 1rem !important;
}

.alternate-visibility {
  transition: all .5s;
  display: block;
}

.alternate-visibility-none {
  transition: all .5s;
  display: none;
}

.search-icon svg {
  fill: var(--dark-purple);
}

/*# sourceMappingURL=index.b94d7142.css.map */
