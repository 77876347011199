:root {
  --color-primary: #2584ff;
  --color-secondary: #00aac7;
  --color-accent: #ff3400;
  --dark-purple: #342862;
  --color-dark-purple-w: #bfb9c7;
  --color-body: #918ca4;
  --light-purple: #5c5577;
  --color-pink-light: #dfbfe4;
}

.pink-light {
  color: var(--color-pink-light);
}

.blue-primary {
  color: var(--color-primary);
}
.blue-secondary {
  color: var(--color-secondary);
}
.dark-purple {
  color: var(--dark-purple);
}
.light-purple {
  color: var(--light-purple);
}
