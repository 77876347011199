.p1 {
    padding: 1rem;
}
.p2 {
    padding: 2rem;
}
.p3 {
    padding: 3rem;
}
.p4 {
    padding: 3rem;
}

.pl1 {
    padding-left: 1rem;
}

.pl2 {
    padding-left: 2rem;
}
.pl3 {
    padding-left: 3rem;
}
.pl4 {
    padding-left: 4rem;
}

.pb1 {
    padding-bottom: 1rem;
}
.pb2 {
    padding-bottom:2rem;
}
.pb3 {
    padding-bottom: 3rem;
}
.pb4 {
    padding-bottom: 4rem;
}

.pt1 {
    padding-top: 1rem;
}
.pt2 {
    padding-top: 2rem;
}
.pt3 {
    padding-top: 3rem;
}
.pt4 {
    padding-top: 4rem;
}

.pr1 {
    padding-right: 1rem;
}
.pr2 {
    padding-right: 2rem;
}
.pr3 {
    padding-right: 3rem;
}
.pr4 {
    padding-right: 4rem;
}
